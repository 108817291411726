import { mapHelper } from "@/utils/common.js";

const userTypeOptions = [
  {
    value: "10",
    label: "居民",
  },
  {
    value: "11",
    label: "商户",
  },
  {
    value: "21",
    label: "运营人员",
  },
];
const { map: userTypeOptionsMap, setOps: userTypeOptionsOps } =
  mapHelper.setMap(userTypeOptions);
const areaOptions = [
  {
    value: "10",
    label: "小区",
  },
  {
    value: "11",
    label: "区域",
  },
  {
    value: "21",
    label: "机构类型",
  },
];

const { map: areaOptionsMap, setOps: areaOptionsOps } =
  mapHelper.setMap(areaOptions);
export { userTypeOptions, userTypeOptionsMap, areaOptionsMap };
