<template>
  <div class="carManager">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="车位号" v-model="searchParam.spaceName" />
        <v-select
          clearable
          :options="userTypeOptions"
          v-model="searchParam.userType"
          label="用户类别"
          @change="changeUserType"
        />
        <community-select
          :spaceTypes="searchParam.userType"
          v-if="searchParam.userType && searchParam.userType != null"
          v-model="searchParam.communityId"
          label="选择园区"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="toEdit(scope.row.id)"
        />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="驳回"
      sureTxt="通过"
    >
      <span>是否审核通过?</span>
    </v-dialog>
  </div>
</template>

<script>
import roomLevel from "@/components/bussiness/roomLevel/roomLevel.vue";
import { getCarportListURL } from "./api.js";
import { userTypeOptions } from "./map";

export default {
  name: "accountList",
  data() {
    return {
      userTypeOptions,
      isdialog: false,
      id: "",

      searchParam: {
        communityId: "",
        spaceName: "",
        userType: "",
      },
      extraParams: {},
      communitList: [],
      tableUrl: getCarportListURL,
      headers: [
        {
          prop: "communityName",
          label: "所在小区",
        },
        {
          prop: "name",
          label: "车位号",
        },
        {
          prop: "path",
          label: "车位信息",
        },
        // {
        //   prop: "gender",
        //   align: "center",
        //   label: "性别",
        //   formatter: (row, prop) => {
        //     return sexOptionsMap[row.gender];
        //   },
        // },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    changeUserType() {
      this.searchParam.communityId = "";
    },
    selectable(row) {
      if (row.auditStatus != 0) {
        return false;
      }
      return true;
    },
    cancel() {
      this.handleRoomUrl(this.id, 2);
    },
    confirm() {
      this.isdialog = false;
      this.handleRoomUrl(this.id, 1);
    },
    toEdit(id) {
      this.isdialog = true;
      this.id = id;
    },

    batchLineSend(row, type) {
      let id = row.id;
      this.send(id, type);
    },
    batchSend(list, type) {
      console.log("list--", list);
      const { data, ids } = list;
      let id = ids.join(",");
      //   this.send(id, type);
      this.toEdit(id);
    },
  },
};
</script>
<style lang="less" scoped>
.carManager {
  box-sizing: border-box;
  height: 100%;
}
</style>
